<template>
  <div class="box mt-80-mb-140">
    <SearchResultListVue
      @clickItem="clickItem"
      :searchResult="searchResult"
    ></SearchResultListVue>
    <PaginationBox
      :total="pageObj.total_count"
      v-model:currentPage="currentPage"
    ></PaginationBox>
  </div>
</template>
<script setup>
import SearchResultListVue from '../../../components/SearchResultList.vue'
import PaginationBox from '@/components/PaginationBox'
import { getSearch } from '../../../server/search'
import { ref, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()

const searchResult = ref([])
const currentPage = ref(1)
const keywords = computed(() => route.query.keywords)
const pageObj = ref({})

onMounted(() => {
  fetch()
})

const fetch = async () => {
  const r = await getSearch(keywords.value, currentPage.value)
  searchResult.value = r.list
  pageObj.value = r.cur_page
}

const clickItem = ({ newsid, catid }) => {
  // 分类id（2表示集团新闻，3员工天地，8学院新闻）
  const path = getCatIdPath(catid)
  router.push({
    path: path,
    query: { newsid: newsid }
  })
}
watch(
  () => keywords.value,
  async () => {
    currentPage.value = 1
    fetch()
  }
)
watch(
  () => currentPage.value,
  async () => {
    console.log('currentPage.value', currentPage.value)
    fetch()
  }
)

function getCatIdPath(catid) {
  // 分类id（2表示集团新闻，3员工天地，8学院新闻）
  let path = ''
  switch (catid) {
    case 2:
      path = '/NewsCenter/NewsDetail'
      break
    case 3:
      path = '/NewsCenter/NewsDetailStaff'
      break
    case 8:
      path = '/HuaduCollege/TrainDetail'
      break
  }

  return path
}
</script>
<style lang="stylus" scoped>
.box
  min-height 350px
</style>
