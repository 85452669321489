<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xxl-12 col-xl-12">
        <div
          class="list d-flex align-items-center"
          v-for="item in searchResult"
          :key="item"
          @click.capture="clickList(item.newsid, item.catid)"
        >
          <div class="icon"></div>
          <div class="text">
            {{ item.title }}
          </div>
          <div class="time">{{ item.create_at }}</div>
          <div class="who">{{ item.comfrom }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps } from 'vue'
defineProps({
  searchResult: {
    type: Array,
    default: function () {
      return []
    }
  }
})
const emit = defineEmits(['clickItem'])
const clickList = (newsid, catid) => {
  emit('clickItem', { newsid, catid })
}
</script>
<style lang="stylus" scoped>

.list
  margin-bottom 25px
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  cursor pointer
  @media screen and (max-width 768px) {
    margin-bottom 15px
    font-size: 16px;
  }
  .icon
    margin 0 15px
    width 4px
    height 4px
    border-radius 100%
    background #000
    text-align center
  .text
    flex 6
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    word-break break-all
  .time
    flex 1
    margin 0 20px 0 30px
    @media screen and (max-width 768px) {
      display none
    }
    text-align right
  .who
    flex 2
    text-align right
    @media screen and (max-width 768px) {
      display none
    }
</style>
